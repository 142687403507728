import React, { useState, useEffect, useRef } from 'react'

import SEO from './seo'

import styled from 'styled-components'

import { useQuery } from '@apollo/react-hooks'

import { GET_BUSINESS_CARD_BY_SLUG } from './data-provider'

import { useEmailSignatureSlug } from '../hooks'

import { get, isEmpty } from 'lodash-es'

import { Spinner, Container as MainContainer, GOLDEN, Link } from './styles'

import Button from './button'

const EmailSignatureWrapper = styled.div`
    padding: 1rem ${GOLDEN * 1}rem;
    background-color: white;
    font-family: sans-serif;
    line-height: 110%;
    width: 100%;
    min-width: 500px;
`
const EmailSignature = styled.div``

const Name = styled.h2`
    font-size: 14px;
    margin: 0;
`

const Title = styled.h3`
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    color: gray;
`
const OrganizationName = styled.h3`
    font-size: 14px;
    margin: 0;
    color: gray;
    font-weight: normal;
`

const ItemTitle = styled.span`
    margin-right: 0.5rem;
    display: table-cell;
`

const List = styled.div`
    list-style: none;
    padding: 0;
    margin: 20px 0 0 0;
`

const Container = styled(MainContainer)`
    min-height: 90vh;
    display: flex;
    ${'' /* align-items: center; */}
    justify-content: center;
    flex-direction: column;
`

const ListItem = styled.div`
    font-size: 12px;
    margin-bottom: 5px;
    display: table-row;
`

const ListItemLink = styled(Link)`
    user-select: all;
    display: table-cell;
`

const Value = styled.span``

const ImageElem = styled.img`
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0 10px 0 0;
`

const HelpRow = styled.div`
    display: flex;
    padding-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
`

const Help = styled.div`
    margin-right: 1rem;
`

const Confidential = styled.p`
    font-size: 0.7rem;
    color: gray;
`

function formatWebsite(website) {
    if (!website.match('http')) {
        return `http://${website}`
    }
    return website
}

function copyElement(elem) {
    if (typeof window === 'undefined') return
    let range
    if (document.selection) {
        // IE
        range = document.body.createTextRange()
        range.moveToElementText(elem)
        range.select()
    } else if (window.getSelection) {
        range = document.createRange()
        range.selectNode(elem)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
    }

    document.execCommand('copy')
}

function clearSelection() {
    setTimeout(() => {
        if (typeof window === 'undefined') return

        if (window.getSelection) {
            window.getSelection().removeAllRanges()
        } else if (document.selection) {
            document.selection.empty()
        }
    }, 50)
}

export default () => {
    const [slug] = useEmailSignatureSlug()
    const [card, setCard] = useState(null)

    const emailSignatureRef = useRef({})

    // eslint-disable-next-line
    const { loading, error, data: remoteData } = useQuery(
        GET_BUSINESS_CARD_BY_SLUG,
        {
            variables: { slug },
        }
    )

    useEffect(() => {
        const remoteCard = get(remoteData, 'businessCard')
        if (remoteCard) setCard(remoteCard)
    }, [remoteData])

    if (!card) {
        return (
            <Container>
                <Spinner />
            </Container>
        )
    }

    return (
        <Container>
            <SEO title={'Get email signature'} />
            <HelpRow>
                <Help>
                    Click on copy and paste it as a signature in your preferred
                    mail application
                </Help>
                <Button
                    onClick={() => {
                        copyElement(emailSignatureRef.current)
                        clearSelection()
                    }}
                >
                    Copy
                </Button>
            </HelpRow>
            <EmailSignatureWrapper>
                <EmailSignature ref={emailSignatureRef}>
                    <table borderCollapse="collapse">
                        <tr>
                            <td>
                                <a
                                    href={'/bc/' + card.slug}
                                    target="blank"
                                    rel="no-opener no-referer"
                                >
                                    <ImageElem
                                        src={
                                            process.env.GATSBY_API_ROOT +
                                            '/cropped/' +
                                            card.slug
                                        }
                                        alt="Logo"
                                    />
                                </a>
                            </td>
                            <td>
                                <Name>
                                    {card.firstName + ' ' + card.lastName}
                                </Name>
                                <Title>{card.title}</Title>
                                <OrganizationName>
                                    {card.organizationName}
                                </OrganizationName>

                                <List>
                                    <ListItem>
                                        <ItemTitle>email</ItemTitle>
                                        <ListItemLink
                                            inline
                                            href={'mailto:' + card.email}
                                        >
                                            {card.email}
                                        </ListItemLink>
                                    </ListItem>
                                    <ListItem>
                                        <ItemTitle>mobile</ItemTitle>
                                        <ListItemLink
                                            inline
                                            href={`tel:${card.workCell}`}
                                        >
                                            {card.workCell}
                                        </ListItemLink>
                                    </ListItem>
                                    <ListItem>
                                        <ItemTitle>phone</ItemTitle>
                                        <ListItemLink
                                            inline
                                            href={`tel:${card.workPhone}`}
                                        >
                                            {card.workPhone}
                                        </ListItemLink>
                                    </ListItem>
                                    {!isEmpty(card.website) && (
                                        <ListItem>
                                            <ItemTitle>website</ItemTitle>
                                            <ListItemLink
                                                target="_blank"
                                                href={formatWebsite(
                                                    card.website
                                                )}
                                            >
                                                {card.website}
                                            </ListItemLink>
                                        </ListItem>
                                    )}
                                    <ListItem>
                                        <ItemTitle>address</ItemTitle>
                                        <Value small>{card.address}</Value>
                                    </ListItem>

                                    <ListItem>
                                        <ItemTitle>license no.</ItemTitle>
                                        <Value>{card.licenseNumber}</Value>
                                    </ListItem>
                                </List>
                            </td>
                        </tr>
                    </table>

                    <Confidential>
                        This message is confidential. It may also be privileged
                        or otherwise protected by work product immunity or other
                        legal rules. If you have received it by mistake, please
                        let us know by e-mail reply and delete it from your
                        system; you may not copy this message or disclose its
                        contents to anyone. Please send us by email any message
                        containing deadlines as incoming e-mails are not
                        screened for response deadlines. The integrity and
                        security of this message cannot be guaranteed on the
                        Internet.
                    </Confidential>
                    <Confidential>
                        Please consider the environment before printing this
                        e-mail.
                    </Confidential>
                </EmailSignature>
            </EmailSignatureWrapper>
        </Container>
    )
}

import React from 'react'

import SEO from '../components/seo'

import Layout from '../components/layout'

import EmailSignature from '../components/email-signature'

import Header from '../components/header'

import Footer from '../components/footer'

export default () => {
    return (
        <Layout>
            <SEO
                description={`The only verified email signature you will ever need.`}
            />
            <Header mode="site-header" />

            <EmailSignature />

            <Footer />
        </Layout>
    )
}
